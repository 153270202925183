import React, { useEffect, useState } from 'react';
import {
  EntityLevelAttributeAssuranceGraphQL, EntityWithAttributeAssurances,
  MaterialsWithRelations,
  SustainabilityAttributeAssuranceGraphQL,
} from '@coldpbc/interfaces';
import { useAuth0Wrapper, useGraphQLSWR } from '@coldpbc/hooks';
import {
  BubbleList,
  ErrorFallback,
  MuiDataGrid,
  Spinner,
  SustainabilityAttributeColumnList,
} from '@coldpbc/components';
import {
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridValidRowModel,
} from '@mui/x-data-grid';
import {filter, get, has, uniq} from 'lodash';
import {
  listFilterOperators,
  listSortComparator, processEntityLevelAssurances,
  processSustainabilityAttributeDataFromGraphQL,
} from '@coldpbc/lib';
import { withErrorBoundary } from 'react-error-boundary';
import {useFlags} from "launchdarkly-react-client-sdk";
import { useNavigate } from 'react-router-dom';

const _MaterialsDataGrid = () => {
  const ldFlags = useFlags();
  const navigate = useNavigate();
  const { orgId } = useAuth0Wrapper();
  const [materials, setMaterials] = useState<MaterialsWithRelations[]>([]);
  const materialsWithRelations = useGraphQLSWR<{
    materials: MaterialsWithRelations[];
  }>(orgId ? 'GET_ALL_MATERIALS_FOR_ORG' : null, {
    filter: {
      organization: {
        id: orgId,
      },
    },
  });

  const renderName = (params: any) => {
    const name = get(params, 'row.name', '')
    const category = get(params, 'row.materialCategory', '')
    const subcategory = get(params, 'row.materialSubcategory', '')
    const text = [category, subcategory]
      .filter((i: string) => (i !== ''))
      .join(' | ');

    return (
      <div className={'flex flex-col w-full h-full justify-center gap-[2px]'}>
        <div className={'w-full h-auto items-center text-body font-bold truncate'}>
          <span>{name}</span>
        </div>
        {
          text &&
          <div className={'w-full h-auto items-center text-body text-tc-disabled truncate'}>
            <span>{text}</span>
          </div>
        }
      </div>
    )
  }

  useEffect(() => {
    if (materialsWithRelations.data) {
      if (has(materialsWithRelations.data, 'errors')) {
        setMaterials([]);
      } else {
        const materials = get(materialsWithRelations.data, 'data.materials', []);
        setMaterials(materials);
      }
    }
  }, [materialsWithRelations.data]);

  const uniqSusAttributes = uniq(
    materials
      .map(material =>
        material.attributeAssurances.map(assurance => {
          return assurance.sustainabilityAttribute.name;
        }),
      )
      .flat(),
  );
  const uniqTier1Suppliers = uniq(
    materials
      .flatMap(material => material.productMaterials.map(productMaterial => productMaterial.product.organizationFacility?.name))
      .filter(name => name !== undefined),
  );

  const uniqTier2Suppliers = uniq(
    materials
      .map(material => material.materialSuppliers.filter(supplier => supplier.organizationFacility.supplierTier === 2).map(supplier => supplier.organizationFacility.name))
      .flat(),
  );

  if (materialsWithRelations.isLoading) {
    return <Spinner />;
  }

  const uniqCategories = uniq(
		materials.map(material =>  material.materialCategory || ''),
  ).filter(Boolean).sort( (a, b) => a.localeCompare(b));

  const uniqSubCategories = uniq(
    materials.map(material =>  material.materialSubcategory || ''),
  ).filter(Boolean).sort( (a, b) => a.localeCompare(b));

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: 'bg-gray-30 h-[37px] text-body',
      flex: 1,
      minWidth: 230,
      renderCell: renderName,
    },
    {
      field: 'tier2Supplier',
      headerName: 'Tier 2 Supplier',
      headerClassName: 'bg-gray-30 h-[37px] text-body',
      flex: 1,
      minWidth: 230,
      type: 'singleSelect',
      valueOptions: uniqTier2Suppliers,
    },
    {
      field: 'usedBy',
      headerName: 'Used By',
      headerClassName: 'bg-gray-30 h-[37px] text-body',
      type: 'singleSelect',
      valueOptions: uniqTier1Suppliers,
      valueFormatter: value => `[${(value as Array<string>).join(', ')}]`,
      renderCell: (params) => {
        return <BubbleList values={params.value as string[]} />;
      },
      minWidth: 350,
      flex: 1,
      sortComparator: listSortComparator,
      filterOperators: listFilterOperators,
    },
    {
      field: 'sustainabilityAttributes',
      headerName: 'Sustainability Attributes',
      headerClassName: 'bg-gray-30 h-[37px] text-body',
      type: 'singleSelect',
      valueOptions: uniqSusAttributes,
      valueFormatter: value => `[${(value as Array<string>).join(', ')}]`,
      renderCell: (params) => {
        return <SustainabilityAttributeColumnList sustainabilityAttributes={params.value} />;
      },
      minWidth: 206,
      flex: 1,
      sortComparator: listSortComparator,
      filterOperators: listFilterOperators,
    },
    {
      field: 'materialCategory',
      headerName: 'Category',
      headerClassName: 'bg-gray-30 h-[37px] text-body',
      flex: 1,
      minWidth: 230,
      type: 'singleSelect',
      valueOptions: uniqCategories,
    },
    {
      field: 'materialSubcategory',
      headerName: 'Sub Category',
      headerClassName: 'bg-gray-30 h-[37px] text-body',
      flex: 1,
      minWidth: 230,
      type: 'singleSelect',
      valueOptions: uniqSubCategories,
    },
  ];

  const newRows: GridValidRowModel[] = [];

  materials.forEach(material => {
    // For now, we just grab the tier 1 supplier of the first product that uses the material
    const tier1Suppliers = material.productMaterials.map(pm => pm.product.organizationFacility).filter(supplier => supplier !== null);
    // While the database schema allows for multiple MaterialSuppliers, we insist on 1 per Material
    const tier2Supplier = material.materialSuppliers[0]?.organizationFacility;

    const sustainabilityAttributes = processEntityLevelAssurances([material]);

    const row = {
      id: material.id,
      name: material.name,
      materialCategory: material.materialCategory || '',
      materialSubcategory: material.materialSubcategory || '',
      sustainabilityAttributes: sustainabilityAttributes,
      tier2Supplier: tier2Supplier ? tier2Supplier.name : '',
      usedBy: uniq(tier1Suppliers.map(supplier => supplier.name).sort((a,b) => a.localeCompare(b))),
    };
    newRows.push(row);
  });

  const rows: GridValidRowModel[] = newRows;

  return (
    <div className={'w-full'}>
      <MuiDataGrid
        rows={rows}
        onRowClick={(params) => {
          if(ldFlags.materialDetailPageCold997){
            navigate(`/materials/${params.id}`)
          }
        }}
        columns={columns}
        columnHeaderHeight={55}
        rowHeight={72}
        showManageColumns
        showExport
        showSearch
      />
    </div>
  );
};

export const MaterialsDataGrid = withErrorBoundary(_MaterialsDataGrid, {
  FallbackComponent: props => <ErrorFallback {...props} />,
});
